import type { ComponentProps, ReactElement } from 'react';

import { DidomiSDK } from '@hubcms/ui-didomi';

import { isCookieConsentDisabled } from '../../../util/isCookieConsentDisabled';

export function DidomiHead(props: ComponentProps<typeof DidomiSDK>): ReactElement | null {
  if (isCookieConsentDisabled()) {
    return null;
  }

  return <DidomiSDK {...props} />;
}
