import type { PageViewParams } from '../domain/page-view-params';

export function isSamePageViewParams(
  pageViewParamsOne: Partial<PageViewParams>,
  pageViewParamsTwo: Partial<PageViewParams> | null,
) {
  if (pageViewParamsTwo !== null) {
    const pageViewParamsOneKeys = Object.keys(pageViewParamsOne).sort();
    const pageViewParamsTwoKeys = Object.keys(pageViewParamsTwo).sort();
    if (pageViewParamsOneKeys.length === pageViewParamsTwoKeys.length) {
      return pageViewParamsOneKeys.every((pageViewParamsKey, index) => {
        const pageViewParamsTwoKey = pageViewParamsTwoKeys[index];
        if (pageViewParamsKey === pageViewParamsTwoKey) {
          return (
            pageViewParamsOne[pageViewParamsKey as keyof PageViewParams] ===
            pageViewParamsTwo[pageViewParamsKey as keyof PageViewParams]
          );
        }
        return false;
      });
    }
  }
  return false;
}
