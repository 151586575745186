import type { useEnv } from '@hubcms/data-access-env';
import { getViewId } from '@hubcms/utils-tracking';

import type { PageParams } from '../domain/page-params';
import type { PageViewParams } from '../domain/page-view-params';
import type { UserParams } from '../domain/user-params';
import type { PaywallParams } from '../domain/paywall-params';
import type { ArticleParams } from '../domain/article-params';
import type { RecommendationParams } from '../domain/recommendation-params';

// eslint-disable-next-line max-params
export function createPageViewParams(
  pageParams: Partial<PageParams>,
  userParams: Partial<UserParams>,
  paywallParams: Partial<PaywallParams>,
  articleParams: Partial<ArticleParams>,
  recommendationParams: RecommendationParams,
  env: ReturnType<typeof useEnv>,
  brandcode: PageViewParams['brandcode'],
  overrideParams: Partial<PageParams> & Partial<PaywallParams>,
): PageViewParams {
  return {
    event: 'page_view',
    application: 'www',
    brandcode,
    environment: (env?.MH_ENV?.toLowerCase() as PageViewParams['environment']) ?? null,
    isnewdataformat: true,
    viewid: getViewId(),
    ...pageParams,
    ...userParams,
    ...paywallParams,
    ...articleParams,
    ...recommendationParams,
    ...overrideParams,
  };
}
