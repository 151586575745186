import type { AuthMethod, ApiIdentity } from '@hubcms/domain-auth';

import type { UserParams } from '../domain/user-params';

type GetUserParamsParams = {
  user: ApiIdentity['user'] | null;
  allowedProducts: string[];
  authMethod: AuthMethod;
  pageSubType: string | null;
};

export function getUserParams({ user, allowedProducts, authMethod, pageSubType }: GetUserParamsParams): UserParams {
  let subscriptiontype: string | null = null;
  if (!authMethod || authMethod === 'none') {
    return {
      identityaccountid: null,
      identityauthenticationlevel: 0,
      identityaccounttype: null,
      subscriptionstatus: null,
      subscriptiontype,
      subscriptionid: null,
    };
  }

  const identityaccountid = user?.sub ?? null;
  let subscriptionstatus: 0 | 1 | null = null;

  if (identityaccountid) {
    subscriptionstatus = allowedProducts.length > 0 ? 1 : 0;
    subscriptiontype = allowedProducts.length > 0 ? allowedProducts.join(',') : null;
  }
  const subscriptionid = null;

  if (authMethod === 'ciamAuth0') {
    const subscriptionStatusCriteria = pageSubType === 'e-paper' ? 'publication' : 'premium';
    return {
      identityaccountid,
      subscriptionstatus: allowedProducts.some(product => product.includes(subscriptionStatusCriteria)) ? 1 : 0,
      loginstatus: identityaccountid ? 1 : 0,
      identitylevels: user?.identityLevels ?? [],
      subscribedaccesses: allowedProducts ?? [],
    };
  }

  return {
    identityaccountid,
    identityauthenticationlevel: identityaccountid ? 1 : 0,
    identityaccounttype: null,
    subscriptionstatus,
    subscriptiontype,
    subscriptionid,
  };
}
