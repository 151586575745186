import { isAccesswalltype } from './isAccesswalltype';

export function extractAccessloginshownFromTrackingData(trackingData: Record<string, unknown>) {
  if ('accessloginshown' in trackingData && typeof trackingData.accessloginshown === 'boolean') {
    return trackingData.accessloginshown;
  }
  if ('accesswalltype' in trackingData && isAccesswalltype(trackingData.accesswalltype)) {
    return ['paywall', 'regwall'].includes(trackingData.accesswalltype);
  }
  return null;
}
